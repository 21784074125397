.page-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  max-width: 1000px;
}

.page-content {
  padding: 20px 40px;
}

@media screen and (max-width: 1000px) {
  .page-container {
    padding: 0;
  }

  .page {
    margin: 0;
    border-radius: 0 !important;
    min-height: 100vh;
    max-width: 100%;
  }

  .page-content {
    padding: 0 15px 10px 15px;
  }
}

.tabs {
  border-bottom: 1px solid #ccc;
}
