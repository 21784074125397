.example {
  margin: 20px;
  position: relative;
  font-weight: 300;
}

.example .description {
  display: block;
  font-weight: 700;
  padding-bottom: 2px;
}

.example .screen {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 400px;
  display: flex;
  flex-direction: column;
}

.example .input,
.example .output {
  display: block;
  padding: 10px;
  background: #eee;
}

.example .output {
  text-align: right;
}
.example .output:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.example .output.error {
  color: #f00;
}

@media screen and (max-width: 500px) {
  .example .screen {
    width: auto;
  }
}
