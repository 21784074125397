.syntax {
  margin-left: 20px;
}

.type {
  display: inline-block;
  line-height: 12px;
  padding: 2px 5px;
  background: #333;
  color: #fff;
  border-radius: 3px;
}

.parameter-type {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: default;
}

.function-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.function-name {
  flex: 1 1 0;
  max-width: 300px;
}

.function-symbol {
  flex: 0 0 auto;
  display: inline-block;
  line-height: 1;
  padding: 2px 5px;
  border-radius: 5px;
  align-self: flex-start;
}

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.matrix {
  display: inline-block;
  border: 2px solid #000;
  vertical-align: middle;
  margin: 0 2px;
}

.matrix-inner {
  display: flex;
  margin: -3px 3px;
  padding: 3px 1px;
  background: #eee;
}

.matrix-row {
  display: flex;
  flex-direction: column;
}

.matrix-cell {
  display: table-cell;
  text-align: center;
  padding: 3px 4px;
  line-height: 1;
}

.syntax-where {
  font-size: 12px;
}

.syntax-list {
  margin: 0;
}
