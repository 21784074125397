.table-of-contents {
  align-self: flex-start;
  margin-bottom: 20px;
}

.nested-listitem {
  padding-left: 2em !important;
}

.screen-overview-container {
  width: 60%;
  position: relative;
  display: inline-block;
  padding-bottom: 111.8%;
  margin-bottom: 20px;
}

.screen-overview {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.screen-overview img {
  max-width: 100%;
}

.legend {
  position: absolute;
  right: 0;
  border-width: 0 0 2px 2px;
  border-color: #fff;
  border-style: solid;
  border-radius: 0 0 0 10px;
}

.legend-horizontal {
  border-radius: 0;
}

.legend-black {
  border-color: #000;
}

.legend-top {
  border-width: 2px 0 0 2px;
  border-radius: 10px 0 0 0;
}

.legend-top .legend-label {
  bottom: 100%;
}

.legend-label {
  display: block;
  padding-left: 20px;
  padding-right: 2px;
  white-space: nowrap;
  position: absolute;
  left: 100%;
  bottom: -2px;
  border-bottom: 2px solid #000;
  line-height: 1.1;
}

@media screen and (max-width: 1000px) {
  .legend-label {
    border-bottom-width: 1px;
    bottom: -1px;
    font-size: 10px;
  }

  .legend {
    border-width: 0 0 1px 1px;
  }

  .legend-top {
    border-width: 1px 0 0 1px;
  }
}

.paid-version {
  color: #d33;
}

.button-sequence {
  white-space: nowrap;
}

.button {
  display: inline-block;
  width: 39px;
  height: 26px;
  line-height: 26px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  margin: 2px 2px 2px 0;
  position: relative;
  padding-top: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 13px;
}

.button-basic-operation,
.button-function {
  color: #fff;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.button-numeric {
  background: #f2ede4;
  color: #000;
}

.button-function {
  background: #2f3540;
}

.button-basic-operation {
  background: #666a73;
}

.button .shift,
.button .alpha {
  position: absolute;
  top: 1px;
  font-size: 0.6em;
  line-height: 1;
  opacity: 0.6;
}

.button .shift {
  left: 2px;
}

.button .alpha {
  right: 2px;
}
